import { SelectProps } from '@rebass/forms';
import * as React from 'react';
import { usePrimitives } from '../../hooks/useTheme';
import { Flex, Box } from '.';
import { Icon } from '..';

export const Select = React.forwardRef(function InputWithRef(
  props: SelectProps & {
    onChangeValue?: (newValue: string) => void;
  },
  ref: React.Ref<any>
): React.ReactElement {
  const { sx, onChangeValue, ...rest } = props;

  const { input } = usePrimitives();

  const onChange = (e: React.SyntheticEvent) => {
    if (onChangeValue) {
      onChangeValue(e.currentTarget.value);
    }

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <Flex>
      <Box
        ref={ref}
        as="select"
        variant="select"
        {...rest}
        onChange={(e: React.SyntheticEvent) => {
          if (onChangeValue) {
            onChangeValue(e.currentTarget.value);
            return;
          }
          onChange(e);
        }}
        sx={{
          display: 'block',

          p: 2,
          appearance: 'none',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          border: 1,
          borderColor: 'border',
          borderRadius: 'radius',
          color: 'inherit',
          bg: 'transparent',
          ...input.styles,
          ...sx
        }}
      />
      <Icon.ChevronDown
        size="small"
        sx={{
          ml: -24,
          color: 'text',
          alignSelf: 'center',
          pointerEvents: 'none'
        }}
      />
    </Flex>
  );
});

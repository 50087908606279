import * as React from 'react';
import { Box, Text, BoxProps, Flex } from '../primitives';

export function Section(
  props: BoxProps & {
    title: string;
  }
): React.ReactElement {
  const { children, title, ...rest } = props;

  return (
    <Box {...rest}>
      <Box ml={'4px'} mb={1}>
        <Text variant={'overline'}>{title}</Text>
      </Box>
      <Box px={4} py={4} elevation={3}>
        {children}
      </Box>
    </Box>
  );
}

export function SectionActions(props: {
  children: React.ReactElement[];
}): React.ReactElement {
  const { children } = props;
  return (
    <Box
      mb={-4}
      mt={4}
      sx={{ userSelect: 'none', borderTop: 1, borderColor: 'border', py: 3 }}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        {children}
      </Flex>
    </Box>
  );
}

import * as React from 'react';
import { Box, Flex, Text } from '../primitives';
import { BoxProps } from 'rebass';

/*
 * Form group with a label.
 */
export function FormGroup(
  props: BoxProps & {
    title?: string;
    badge?: React.ReactElement;
    error?: Error | string | null;
    children: React.ReactElement;
    size?: 'normal' | 'small';
  }
): React.ReactElement {
  const {
    title,
    badge,
    children,

    size = 'normal',
    error,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      {title && (
        <Flex mb={size === 'small' ? '0px' : 1}>
          <Text variant={size === 'small' ? 'body3' : 'subtitle3'}>
            {title}
          </Text>
          {badge}
        </Flex>
      )}
      {children}
      {error && (
        <Box
          mt={1}
          px={2}
          py={1}
          sx={{ bg: 'secondary', color: 'white', borderRadius: 'rounded' }}
        >
          <Text variant="body2">
            {error instanceof Error ? error.message : error}
          </Text>
        </Box>
      )}
    </Box>
  );
}

import * as React from 'react';
import { Text, Box } from './primitives';
import { PortalContent, PortalRelative } from './Portal';

/*
 * The tooltip component should be used to add a helpful message on a dark overlay.
 *
 *  <Tooltip content="Edit title">
 *    <Button icon={<IconEdit />} />
 *  </Tooltip>
 */
export function Tooltip(props: {
  // Text content of the tooltip
  content: React.ReactNode;
  // False to hide the tooltip conditionally
  visible?: boolean;
  // Content to wrap
  children:
    | React.ReactNode
    | ((props: { ref: React.Ref<any> }) => React.ReactNode);
}): React.ReactElement {
  const { content, visible, children } = props;
  const [hovered, setHovered] = React.useState(false);
  const anchorRef = React.useRef();
  const portalRef = React.useRef();

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  return (
    <PortalRelative
      ref={portalRef}
      anchorRef={anchorRef}
      opened={hovered && visible}
      anchor={({ ref }) => {
        if (typeof children === 'function') {
          return children({ ref, onMouseEnter, onMouseLeave });
        }

        return React.cloneElement(React.Children.only(children), {
          ref,
          onMouseEnter,
          onMouseLeave
        });
      }}
    >
      {state => {
        return (
          <Box
            sx={{
              position: 'absolute',
              pointerEvents: 'none',
              ...state.position
            }}
          >
            <PortalContent>
              <Box
                sx={{
                  bg: 'oppositeBackground',
                  color: 'oppositeText',
                  borderRadius: 'rounded'
                }}
              >
                <Box py={'4px'} px={2} sx={{ whiteSpace: 'nowrap' }}>
                  {typeof content === 'string' ? (
                    <Text variant={'body2'}>{content}</Text>
                  ) : (
                    <Box>{content}</Box>
                  )}
                </Box>
              </Box>
            </PortalContent>
          </Box>
        );
      }}
    </PortalRelative>
  );
}

import * as React from 'react';
import { useClickOutside } from '../..';
import { PortalAbsolute } from '../Portal';
import { Box, Flex } from '../primitives/Box';
import Backdrop from './Backdrop';

export function Popup(props: {
  children: React.ReactElement;
  onClose?: () => void;
}): React.ReactElement {
  const { children, onClose } = props;
  const ref = React.useRef();
  useClickOutside(ref, () => {
    if (onClose) {
      onClose();
    }
  });

  return (
    <PortalAbsolute>
      <Backdrop>
        <Box ref={ref}>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ bg: 'background', borderRadius: 'rounded' }}
          >
            {children}
          </Flex>
        </Box>
      </Backdrop>
    </PortalAbsolute>
  );
}

import * as React from 'react';
import { SxStyleProp } from 'rebass';
import { Box } from '../primitives';

/*
 * Dark backdrop for modals
 */
function Backdrop(props: {
  sx: SxStyleProp;
  // You can use the backdrop a container with children,
  // or simply as a background
  children: React.ReactElement;
}): React.ReactElement {
  const { sx, children, ...rest } = props;

  return (
    <Box
      role="presentation"
      sx={{
        pointerEvents: 'auto',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'backdrop',
        zIndex: 20,
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default Backdrop;

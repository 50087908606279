import * as React from 'react';
import { Box, Text, Suspense, Flex, BoxProps } from '..';

/*
 * Screen of content to show over the main content.
 */
function Modal(
  props: BoxProps & {
    children: React.ReactElement | React.ReactElement[];
    onClose?: () => void;
  }
): React.ReactElement {
  const { children, ...rest } = props;

  return (
    <Suspense fallback={<Box>Loading</Box>}>
      <Box
        sx={{
          minWidth: [350, 500],
          maxWidth: [350, 'initial'],
          borderRadius: 'rounded',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 50px)'
        }}
        px={5}
        py={3}
        elevation={1}
        {...rest}
      >
        {children}
      </Box>
    </Suspense>
  );
}

function ModalHeader(props: { children: string }): React.ReactElement {
  const { children } = props;

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'border', pb: 1 }}>
      <Text variant={'heading1'}>{children}</Text>
    </Box>
  );
}

function ModalBody(props: {
  children: React.ReactElement | React.ReactElement[];
}): React.ReactElement {
  const { children, ...rest } = props;

  return (
    <Box pt={4} pb={4} {...rest}>
      {children}
    </Box>
  );
}

function ModalFooter(props: {
  children: React.ReactElement | React.ReactElement[];
}): React.ReactElement {
  const { children } = props;

  return <Flex justifyContent={'space-between'}>{children}</Flex>;
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export { Modal };

import * as React from 'react';
import ContentLoader from 'react-content-loader';

import { useTheme } from '../../hooks';

export const TextPlaceholder = (props: any) => {
  const theme = useTheme();

  return (
    <ContentLoader
      speed={0}
      width={200}
      height={10}
      primaryColor={theme.colors.bgPlaceholderPrimary}
      secondaryColor={theme.colors.bgPlaceholderSecondary}
      style={{
        width: '100%',
        height: '100%'
      }}
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="200" height="10" />
    </ContentLoader>
  );
};

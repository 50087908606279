import * as React from 'react';
import { Box, BoxProps } from '../primitives';

export function Form(
  props: {
    children: React.ReactElement | React.ReactElement[];
  } & BoxProps
): React.ReactElement {
  const { children } = props;

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (props.onSubmit) {
      props.onSubmit(e);
    }
  };

  return (
    <Box as="form" {...props} onSubmit={onSubmit}>
      {children}
    </Box>
  );
}

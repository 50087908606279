import * as React from 'react';

// Type definitions for Form.useFormState static export
type HasChangedOption<T> = (value: T, prevValue: T) => boolean;
type StateUpdateArg<T> = T | ((prevValue: T) => T);

/*
 * Hook to easily build a form with a submit button.
 */
export function useFormState<T>(
  initialValue: T,
  hasChanged: HasChangedOption<T> = (a: T, b: T) => {
    return a !== b;
  }
): [T, (input: StateUpdateArg<T>) => void, boolean, (input: T) => void] {
  const initialState = {
    initialValue,
    hasChanged: false,
    value: initialValue
  };
  const [state, setState] = React.useState(initialState);

  const setValue = React.useCallback((input: StateUpdateArg<T>) => {
    setState(currentState => {
      const newValue =
        typeof input === 'function' ? input(currentState.value) : input;

      return {
        value: newValue,
        initialValue: currentState.initialValue,
        hasChanged: hasChanged(currentState.initialValue, newValue)
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset state when the initial value changes
  React.useEffect(() => {
    setState(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged(state.initialValue, initialValue)]);

  return [state.value, setValue, state.hasChanged];
}

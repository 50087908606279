import { useEffect, useRef, useState } from 'react';

export function useHover() {
  const [value, setValue] = useState(false);

  const container = useRef();

  const handleMouseEnter = () => setValue(true);
  const handleMouseLeave = () => setValue(false);

  useEffect(() => {
    const node = container.current;
    if (!node) {
      return;
    }
    node.addEventListener('mouseenter', handleMouseEnter);
    node.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      if (!node) {
        return;
      }

      node.removeEventListener('mouseenter', handleMouseEnter);
      node.removeEventListener('mouseleave', handleMouseLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.current]);

  return [container, value];
}

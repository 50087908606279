import * as React from 'react';
import { Box } from './Box';
import { Flex, Icon } from '@churni/styleguide';
import { SxStyleProp } from 'rebass';

export const InlineCard = React.forwardRef(function InlineCardWithRef(
  props: {
    children: string;
    onDelete: () => void;
    sx?: SxStyleProp;
  },
  ref: React.Ref<any>
): React.ReactElement {
  const { children, onDelete, sx = {}, ...rest } = props;

  return (
    <Box
      sx={{ display: 'inline-block', borderRadius: 'rounded', ...sx }}
      elevation={3}
      ref={ref}
      {...rest}
    >
      <Flex alignItems={'center'} justifyContent={'center'}>
        <Box px={2}>{children}</Box>
        {onDelete && (
          <Box
            px={1}
            sx={{ borderLeft: 1, borderColor: 'border', cursor: 'pointer' }}
            onClick={onDelete}
          >
            <Icon.X />
          </Box>
        )}
      </Flex>
    </Box>
  );
});

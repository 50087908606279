import * as React from 'react';

import { Box } from './Box';

export const Switch = React.forwardRef(function SwitchWithRef(
  props: { checked: boolean; onClick: () => void; disabled?: boolean },
  ref
) {
  const { checked, disabled, className } = props;

  return (
    <Box
      ref={ref}
      as="button"
      type="button"
      role="switch"
      tx="forms"
      variant="switch"
      aria-checked={checked}
      {...props}
      sx={{
        opacity: disabled ? 0.5 : 1,
        cursor: 'pointer',
        appearance: 'none',
        m: 0,
        p: 0,
        width: 32,
        height: 14,
        color: 'primary',
        bg: 'inputBorder',
        border: '0px',
        // borderColor: 'border',
        borderRadius: 9999,
        '&[aria-checked=true]': {
          bg: 'primary'
        },
        ':focus': {
          outline: 'none',
          boxShadow: '0 0 0 2px'
        }
      }}
    >
      <Box
        aria-hidden
        className={className}
        style={{
          transform: checked ? 'translateX(18px)' : 'translateX(0)'
        }}
        sx={{
          ml: '2px',
          width: 10,
          height: 10,
          borderRadius: 9999,
          // border: '1px solid',
          // borderColor: 'border',
          bg: 'background',
          transitionProperty: 'transform',
          transitionTimingFunction: 'ease-out',
          transitionDuration: '0.1s',
          variant: 'forms.switch.thumb'
        }}
      />
    </Box>
  );
});

import * as React from 'react';
import { Box } from 'rebass';

/**
 * Hook that react to clicks outside of the passed ref
 */
export function useClickOutside(
  ref: React.Ref<typeof Box>,
  onClickOutside: () => void
) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event: React.SyntheticEvent) {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  }

  React.useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

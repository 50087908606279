import * as React from 'react';
import { Popup } from '../Popup';
import { Modal } from '../Modal';
import { TextPlaceholder } from './TextPlaceholder';

export const PopupPlaceholder = () => {
  return (
    <Popup>
      <Modal>
        <Modal.Header>
          <TextPlaceholder width={6} height={18} />
        </Modal.Header>
        <Modal.Body>
          <TextPlaceholder width={8} height={10} />
          <TextPlaceholder my={2} width={4} height={10} />
          <TextPlaceholder width={6} height={10} />
        </Modal.Body>
      </Modal>
    </Popup>
  );
};

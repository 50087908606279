import * as React from 'react';
import { Box, Flex, Text } from '../primitives';

export function DataTable(props: {
  columns: React.ReactElement[];
  rows: React.ReactElement[][];
  gridTemplateColumns: string;
  footer: React.ReactElement;
  styles: {
    header: object;
    body: object;
  };
}): React.ReactElement {
  const { rows, columns, gridTemplateColumns, styles, footer, ...rest } = props;

  return (
    <Flex flexDirection="column" sx={{ width: '100%' }} elevation={2} {...rest}>
      <Box
        sx={{
          borderTopLeftRadius: 'rounded',
          borderTopRightRadius: 'rounded',
          ...styles.header,
          pt: 2,
          px: 3
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns,
            pb: 2,
            borderBottom: 1,
            borderColor: 'border'
          }}
        >
          {columns.map((col, i) => (
            <Box key={i}>
              <Text variant={'subtitle4'}>{col}</Text>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ ...styles.body }}>
        {!rows.length && (
          <Box sx={{ textAlign: 'center', py: 7 }}>No results</Box>
        )}
        {rows.map((row, r) => (
          <Box key={`row-${r}`} px={3}>
            <Box
              sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns,
                borderBottom: r === rows.length - 1 ? 0 : 1,
                borderColor: 'border'
              }}
            >
              {row.map((cell, i) => (
                <Box
                  key={`row-${r}-cell-${i}`}
                  py={2}
                  px={1}
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {cell}
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <Box px={2} py={2} sx={{ borderTop: 1, borderColor: 'border' }}>
        {footer}
      </Box>
    </Flex>
  );
}
